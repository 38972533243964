body {
  font-family: "Montserrat Regular" !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.rotate {
  animation: rotation 10s infinite linear;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: calc((100vw - 250px) / 2);
  height: 250px;
}

.apexcharts-legend-text {
  color: white;
}

.btn{
  background: green;
  padding: 16px;
  color: white;
  text-decoration: none;
  font-size: 20px;
  margin: 16px;
  font-family: "Montserrat",sans-serif;
  border-radius: 10px;
}

.swal2-container{
  z-index: 100000 !important;
}